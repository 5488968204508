require('./bootstrap');

// cookies bar
$(function() {
    let cookieKiller = $('#cookieKiller');
    let boxGDPR = $('#moduleGDPR');
    let btnGDPR = $('#moduleGDPR #buttonGDPR');

    function setAgreeCookie() {
        let expire = new Date();
        expire = new Date(expire.getTime() + (3600 * 1000) * 8750);
        document.cookie = "boxGDPR=true; expires=" + expire;
    }

    if (document.cookie.indexOf("boxGDPR=") >= 0) {
        $(boxGDPR).addClass('hidden');
    }
    else {
        $(boxGDPR).removeClass('hidden');
    }

    $(btnGDPR).on('click', function () {
        setAgreeCookie();
        $(boxGDPR).addClass('hidden');
    });

    $(cookieKiller).on('click', function () {
        document.cookie = "boxGDPR=true; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        $(boxGDPR).removeClass('hidden');
    });

});


// mega menu
$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    const $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
});

// carousels - defs
const recycleCarousel = $('.homepage.recycle .recycle-carousel');

// heroCarousel
$(recycleCarousel).owlCarousel({
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 4800,
    loop: true,
    lazyLoad: true,
    margin: 30,
    nav: false,
    dots: true,
    items: 1,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 2
        },
        1200: {
            items: 3
        }
    }
});

// @TODO: Nawigacja: karuzela
$('.homepage.hero .carousel-pagination .owl-next').on('click', function () {
    recycleCarousel.trigger('next.owl.carousel');
});
$('.homepage.hero .carousel-pagination .owl-prev').on('click', function () {
    recycleCarousel.trigger('prev.owl.carousel');
});

// AJAX form
$(function () {
    $('.ajax-form').on('submit', function (e) {
        e.preventDefault();

        const $form = $(this);

        $form.find('input').removeClass('is-invalid');
        $form.find('textarea').removeClass('is-invalid');
        $form.closest('div').find('.ajax-form-success').fadeOut();
        $form.find('.ajax-form-error').fadeOut();

        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            dataType: 'JSON',
            success: function (response) {
                if (response.success) {
                    $form.find('input').val('');
                    $form.find('textarea').val('');
                    $form.fadeOut('normal', function () {
                        $form.closest('div').find('.ajax-form-success').html(response.success).fadeIn();
                    });
                }
                if (response.error) {
                    $form.find('.ajax-form-error').html(response.error).fadeIn();
                }
            },
            error: function (response) {
                if (response.responseJSON.errors) {
                    $.each(response.responseJSON.errors, function (key, val) {
                        const $input = $form.find('#' + key);
                        $input.addClass('is-invalid');
                        $input.closest('.form-group').find('.invalid-feedback').html(val);
                    });
                }
            }
        });
    })
});

// accessibility
const accessibility_contrast = localStorage.getItem('accessibility-contrast');

if (accessibility_contrast) {
    setAccessibilityContrast(accessibility_contrast);
}

const accessibility_font_size = localStorage.getItem('accessibility-font-size');

if (accessibility_contrast) {
    setAccessibilityFontSize(accessibility_font_size);
}

$('.contrast-switch').on('click', function (e) {
    e.preventDefault();

    const type = $(this).data('body_class');

    localStorage.setItem('accessibility-contrast', type);
    setAccessibilityContrast(type);
});

$('.font-switch').on('click', function (e) {
    e.preventDefault();

    const type = $(this).data('html_class');

    localStorage.setItem('accessibility-font-size', type);
    setAccessibilityFontSize(type);
});

function setAccessibilityContrast(type) {
    $('body')
        .removeClass(function (index, className) {
            return (className.match(/(^|\s)access-\S+/g) || []).join(' ');
        })
        .addClass(type);
}


function setAccessibilityFontSize(type) {
    $('html')
        .removeClass(function (index, className) {
            return (className.match(/(^|\s)font-size_\S+/g) || []).join(' ');
        })
        .addClass(type);
}
