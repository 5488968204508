window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.owlCarousel = require('owl.carousel');
    window.niceSelect = require('jquery-nice-select');
    window.mapboxgl = require('mapbox-gl');
    window.SimpleLightbox = require('simple-lightbox');
    require('datatables.net-bs4');

    require('bootstrap');
} catch (e) {}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// datatables defaults
$.extend( true, $.fn.dataTable.defaults, {
    language: {
        url: "/vendor/cms/assets/datatables-polish.json",
    },
    processing: true,
    responsive: true,
    order: [1, 'asc'],
    drawCallback: function() {
        $(this).find('.actions-col a').tooltip({ boundary: 'window' });
        $(this).find('.actions-col .delete-btn').on('click', function (e) {
            e.preventDefault();

            const $form = $(this).closest('td').find('form');

            bootbox.confirm({
                message: "Czy na pewno chcesz to usunąć?",
                locale: 'pl',
                backdrop: true,
                callback: function (result) {
                    if(result) {
                        $form.submit();
                    }
                }
            });
        });
    }
} );
